import React from 'react';
import styled from 'styled-components'
import { TransfermarktPlayerCustom } from '../Types/TransfermarktTypes';
import "../styled/playerProfileComponent.scss";


interface Props {
    playerInfo: TransfermarktPlayerCustom
}

const CustomColorDiv = styled.div<{}>`
`;

export const PlayerProfileComponent: React.FC<Props> = ({ playerInfo }) => {
    return (
        <div>

            <div>
                <img alt={playerInfo?.profile.name} src={playerInfo!.profile.imageURL}></img>
                <div>NAME: {playerInfo!.profile.name}</div>
                <div style={{ display: "inline-block" }}>{playerInfo!.profile.citizenship.map(txt => <div key={txt}>{txt}</div>)}</div>
                <div>POSITION: {playerInfo!.profile.position.main} {playerInfo!.profile.position!.other ? <span>{playerInfo!.profile.position!.other!.map(txt => <span key={txt}> | {txt}</span>)}</span> : null}</div>
                <div>TRANSFERS:
                    <ul>
                        {playerInfo!.transfer!.transfers.map((transfer, index) => {
                            return (
                                <li>
                                    <p>{transfer.from.clubName} to {transfer.to.clubName} on {transfer.date.toString()}
                                    </p>
                                    <p dangerouslySetInnerHTML={{ __html: transfer.fee }} />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

        </div>
    )
}