import axios from "axios";
import { closestMatch } from "closest-match";
import React, { useEffect, useState } from "react";
import { SofascoreTopPlayers } from "../Types/SofaScoreTypes";
import { TransfermarktClubPlayerProfile, TransfermarktPlayerProfile } from "../Types/TransfermarktTypes";
import "../styled/app.scss";
import "../styled/primeiraliga.scss";

const PrimeiraLiga = () => {
    //#region constants
    const [standings, setStandings] = useState([]);
    const [standingsRows, setStandingsRows] = useState([]);
    const [tfClubPlayer, setTFClubPlayer] = useState<TransfermarktClubPlayerProfile[]>();
    const [tfPlayerInfo, setTFPlayerInfo] = useState<TransfermarktPlayerProfile>();
    const [isActive, setActive] = useState(false);
    const [isActivePlayer, setActivePlayer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingS, setIsLoadingS] = useState(false);
    const [teamSelected, setTeamSelected] = useState();
    const [playerSelected, setPlayerSelected] = useState<SofascoreTopPlayers>();
    //#endregion

    useEffect(() => {
        const getStandingsList = async () => {
            try {
                const res = await axios.get("https://api.sofascore.com/api/v1/unique-tournament/238/season/52769/standings/total");
                setStandings(res.data["standings"]);
                for (let index = 0; index < res.data["standings"].length; index++) {
                    var teamData = res.data["standings"][0]["rows"];
                    setStandingsRows(teamData);
                    //TODO teamdata as variable with type
                }
            } catch (err) {
                console.log(err);
            }
        };
        getStandingsList();
    }, []);

    //#region functions
    async function searchTransfermarkt(_teamName: string, _id: never) {
        setIsLoading(true);

        if (!isActive) {
            setTeamSelected(_id);
            try {
                const axiosURL = "https://transfermarkt-coutinho.fly.dev/clubs/search/" + _teamName;
                const res = await axios.get(axiosURL);

                let hasResults = res.data["results"].length > 0;
                if (hasResults) getTransfermarkt(res.data["results"][0]["id"]);
                else {
                    let newTeamName = _teamName.split(" ");

                    let nameNew = newTeamName[newTeamName.length-1];
                    console.log(nameNew);

                    const axiosURL1 = "https://transfermarkt-coutinho.fly.dev/clubs/search/" + nameNew;
                    const res1 = await axios.get(axiosURL1);
                    getTransfermarkt(res1.data["results"][0]["id"]);
                }
                console.log();
            } catch (err) {
                console.log(err);
            }
        } else setTeamSelected(undefined);
        setActive(!isActive);
        setActivePlayer(isActive);
        setIsLoading(false);
    }

    async function getTransfermarkt(_teamID: string | never[]) {
        try {
            const axiosURL = "https://transfermarkt-coutinho.fly.dev/clubs/" + _teamID + "/players";
            const res = await axios.get(axiosURL);
            var clubPlayerList: TransfermarktClubPlayerProfile[] = res.data["players"];
            setTFClubPlayer(clubPlayerList);
        } catch (err) {
            console.log(err);
        }
    }

    async function searchSofascorePlayer(_playerName: string, transfermarktID: number) {
        setIsLoadingS(true);

        if (!isActivePlayer) {
            try {
                const axiosURL = "https://api.sofascore.com/api/v1/team/" + teamSelected + "/unique-tournament/238/season/52769/top-players/overall";
                const res = await axios.get(axiosURL);
                //setAPITopPlayers();
                var obj = res.data["topPlayers"]["rating"];

                var playerNameList = [];

                for (let key in obj) {
                    playerNameList.push(obj[key]["player"]["name"]);
                }
                var playerNameInSofaScore = closestMatch(_playerName, playerNameList);
                var objectPlayerSelected = obj.find((player: SofascoreTopPlayers) => player.player.name === playerNameInSofaScore);

                //TODO get only rating from sofascore and rest from TM

                console.log(objectPlayerSelected);

                var feeArray = await getTFPlayerFee(transfermarktID);
                objectPlayerSelected.player.fee = feeArray[0];
                objectPlayerSelected.player.feeN = feeArray[1];

                setTFPlayerInfo(await getTFPlayerInfo(transfermarktID));

                setPlayerSelected(objectPlayerSelected);
            } catch (err) {
                console.log(err);
            }
        } else {
            setTFPlayerInfo(undefined);
            setPlayerSelected(undefined);
        }

        setActivePlayer(!isActivePlayer);
        setIsLoadingS(false);
    }

    async function getTFPlayerInfo(_playerTFid: number): Promise<TransfermarktPlayerProfile> {
        var _tfProfile: TransfermarktPlayerProfile;

        try {
            const axiosURL = "https://transfermarkt-coutinho.fly.dev/players/" + _playerTFid + "/profile";
            const res = await axios.get(axiosURL);
            _tfProfile = res.data;

            //GetCountryFlag(_tfProfile.citizenship); fix errror on countries not on list
        } catch (err) {
            console.log(err);
        }

        return _tfProfile!;
    }

    async function getTFPlayerFee(_playerTFid: number) {
        var playerfeeN = 0;
        try {
            const axiosURL = "https://transfermarkt-coutinho.fly.dev/players/" + _playerTFid + "/transfers";
            const res = await axios.get(axiosURL);

            var playerfee = res.data["transfers"][0]["fee"];
            if (typeof res.data["transfers"][0]["fee"] == "undefined") playerfee = "free transfer";

            var playerfeeInt = parseInt(playerfee.substring(1, playerfee.length - 1));

            switch (playerfee.slice(playerfee.length - 1)) {
                case "k":
                    playerfeeN = playerfeeInt * 1000;
                    break;

                case "M":
                    playerfeeN = playerfeeInt * 1000000;
                    break;

                default:
                    break;
            }
        } catch (err) {
            console.log(err);
        }

        return [playerfee, playerfeeN];
    }

    //#endregion

    //#region actions
    function teamClick(event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>, team: never, id: never): void {
        event.preventDefault();
        searchTransfermarkt(team, id);
    }

    function playerClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, player: string, transfermarktID: number) {
        event.preventDefault();
        searchSofascorePlayer(player, transfermarktID);
    }

    function teamGameStats(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _id: never): void {
        event.preventDefault();
        localStorage.setItem("teamID", _id);
        window.open("/TeamGameStats", "_blank");
    }

    function teamH2HStats(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _id: never): void {
        event.preventDefault();
        localStorage.setItem("teamID", _id);
        window.open("/TeamH2H", "_blank");
    }

    //#endregion

    return (
        <div className="primeiraLiga">
            <div className="primeiraLiga__leagueName">
                <p>Primeira Liga Portugal</p>
            </div>

            {standings.map((dataObj, index) => {
                return (
                    <div className="primeiraLiga__content" key={index}>
                        <div className="primeiraLiga__content--tables" id="tables">
                            <div className="tablePrimeiraLiga" id="standings">
                                <table>
                                    <tbody>
                                        {standingsRows.map((dataObjRows, index1) => {
                                            return (
                                                <tr
                                                    key={index1 + 1}
                                                    id={dataObjRows["team"]["id"]}
                                                    className={(() => {
                                                        if (!teamSelected) {
                                                            return "noTeamSelected";
                                                        } else if (dataObjRows["team"]["id"] === teamSelected) {
                                                            return "teamSelected";
                                                        } else {
                                                            return "notThisTeamSelected";
                                                        }
                                                    })()}>
                                                    <td className="rank">{dataObjRows["position"]}</td>
                                                    <td className="team" onClick={(event) => teamClick(event, dataObjRows["team"]["name"], dataObjRows["team"]["id"])}>
                                                        <div
                                                            className="teamImg"
                                                            style={{
                                                                backgroundImage: `url('https://api.sofascore.app/api/v1/team/${dataObjRows["team"]["id"]}/image')`,
                                                            }}></div>
                                                        <span>{dataObjRows["team"]["name"]}</span>
                                                    </td>
                                                    <td className="points">{dataObjRows["points"]}</td>
                                                    <td className="tblButtons">
                                                        <button className="teamGameStats" id="teamGameStats" onClick={(event) => teamGameStats(event, dataObjRows["team"]["id"])}>
                                                            {" "}
                                                            team stats{" "}
                                                        </button>
                                                        <button className="h2h" id="h2h" onClick={(event) => teamH2HStats(event, dataObjRows["team"]["id"])}>
                                                            {" "}
                                                            team h2h{" "}
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            {isLoading ? <img src="https://tmsi.akamaized.net/head/transfermarkt_logo.svg" height="62" width="156" title="Transfermarkt" alt="Transfermarkt"></img> : null}
                            {isActive && !isLoadingS ? (
                                <div className="tablePlayerData" id="transfermarktData">
                                    <div className="tablePlayerData__table">
                                        <table>
                                            <tbody>
                                                {tfClubPlayer?.map((tfData, index2) => {
                                                    return (
                                                        <tr
                                                            key={tfData!.id}
                                                            id={tfData!.id.toString()}
                                                            onClick={(event) => playerClick(event, tfData!.name, tfData!.id)}
                                                            className={(() => {
                                                                if (!tfPlayerInfo) {
                                                                    return "noPlayerSelected";
                                                                } else if (tfData.id === tfPlayerInfo?.id) {
                                                                    return "playerSelected";
                                                                } else {
                                                                    return "notThisPlayerSelected";
                                                                }
                                                            })()}>
                                                            <td className="name">{tfData!.name}</td>
                                                            <td className="position">{tfData!.position}</td>
                                                            <td className="value">{tfData!.marketValue}</td>
                                                            <td className="age">{tfData!.age}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}
                            {isLoadingS ? (
                                <img
                                    src="https://www.hottype.co/media/pages/projects/sofascore/2df1669cb3-1688117160/hot-type-sofascore-logotype.svg"
                                    height="240"
                                    width="240"
                                    title="Sofascore"
                                    alt="Sofascore"></img>
                            ) : null}
                            {isActivePlayer && isActive ? (
                                <div className="tablePlayerDetails">
                                    <div className="cardPlayerDetails">
                                        <div
                                            className={(() => {
                                                if (playerSelected?.statistics.rating! >= 7.5) {
                                                    return "cardPlayerDetails__rating rating75";
                                                } else if (playerSelected?.statistics.rating! >= 7.0) {
                                                    return "cardPlayerDetails__rating rating70";
                                                } else if (playerSelected?.statistics.rating! >= 6.5) {
                                                    return "cardPlayerDetails__rating rating65";
                                                } else if (playerSelected?.statistics.rating! >= 6.0) {
                                                    return "cardPlayerDetails__rating rating60";
                                                } else {
                                                    return "cardPlayerDetails__rating ratinglow";
                                                }
                                            })()}>
                                            {playerSelected?.statistics.rating.toFixed(2)}
                                        </div>
                                        <img className="cardPlayerDetails__round" src={tfPlayerInfo!.imageURL} alt={playerSelected?.player.name} />
                                        <h3>{playerSelected?.player.name}</h3>
                                        <div className="tablePlayerDetails__citizenship">
                                            <ul>
                                                {tfPlayerInfo!.citizenship.map((txt) => (
                                                    <li key={txt}>{txt}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <p>{playerSelected?.statistics.appearances} appearances</p>
                                        <div className="cardPlayerDetails__positionPrimary">
                                            {" "}
                                            <div className="cardPlayerDetails__positionPrimary--position">{tfPlayerInfo!.position.main}</div>{" "}
                                        </div>
                                        {tfPlayerInfo!.position!.other ? (
                                            <div className="cardPlayerDetails__positionOther">
                                                <ul>
                                                    {tfPlayerInfo!.position!.other!.map((txt) => (
                                                        <li key={txt}>{txt}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default PrimeiraLiga;
