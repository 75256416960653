import React from 'react';
import { DropdownItems } from '../Types/CustomTypes';


interface Props {
    ddItems: DropdownItems[];
    onChangeDrop: Function;
    selectAll?: string;
}

export const CustomDropdown
    : React.FC<Props> = ({ ddItems, onChangeDrop, selectAll }) => {

        return (
            <div className="customDropdown">
                <select defaultValue={""} onChange={(event) => onChangeDrop(parseInt(event.target.value))}>
                    <option value="" disabled hidden>Select Club</option>
                    {selectAll ?
                        <option key="All" value={0}> {selectAll} </option>
                        : null}
                    {ddItems?.map((item, index) => {
                        return (
                            <option key={item.label} value={item.value}> {item.label} </option>
                        );
                    })}
                </select>
            </div>
        )
    }