import React from "react";
import styled from "styled-components";

interface Props {
    budgetUsed: string;
    budgetLeft: string;
    onChangeInput: Function;
}

//linear-gradient(${(props) => props.teamcolor}, ${(props) => props.teamcolor2});
const ContainerComponent = styled.div<{}>``;
const ContainerUsed = styled.div<{}>``;
const SpanUsed = styled.span<{}>``;
const ContainerLeft = styled.div<{}>``;
const SpanLeft = styled.span<{}>``;
const ContainerInput = styled.div<{}>``;
const CustomInput = styled.input<{}>``;

export const BudgetContainer: React.FC<Props> = ({budgetLeft, budgetUsed, onChangeInput}) => {
    return (
        <>
            <ContainerComponent>
                <ContainerInput>
                    <CustomInput type="number" onChange={(event) => onChangeInput(parseInt(event.target.value))}></CustomInput>
                </ContainerInput>
                <ContainerUsed>
                    <SpanUsed>Budget used: {budgetUsed}</SpanUsed>
                </ContainerUsed>
                <ContainerLeft>
                    <SpanLeft>Budget Left:{budgetLeft}</SpanLeft>
                </ContainerLeft>
            </ContainerComponent>
        </>
    );
};
