import React from 'react';
import styled from 'styled-components'
import { SofaScoreEvent } from '../Types/SofaScoreTypes';

interface Props {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    color?: string,
    disabled?: boolean;
    btnText: string;
}

const MyButton = styled.button<{ color?: string, disabled?: boolean }>`
//border: $ {props => (props.teamcolor)};
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  outline: none;
  transition: all 0.2s;
  height: fit-content;
  color: color;
  pointer-events: ${(props)=>props.disabled?'none':null};
`;

export const CustomButton: React.FC<Props> = ({ color, disabled, onClick, btnText }) => {
    return (
        <MyButton onClick={onClick} color={color} disabled={disabled}>
            {btnText}
        </MyButton>
    )
}