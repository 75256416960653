import axios from 'axios';
import { useEffect, useState } from 'react';
import { TeamH2H } from '../Types/CustomTypes';
import { SofaScoreEvent, SofaScoreTeam } from "../Types/SofaScoreTypes";
import { ContainerMatchResult } from "../components/ContainerMatchResult";
import "../styled/styles.css";
import "../styled/teamgamestats.scss";

const TeamGameStats = () => {
    const [teamData, setTeamData] = useState<SofaScoreTeam>();
    const [teamEvents, setTeamEvents] = useState<SofaScoreEvent[]>();

    async function getSofaScoreResults(_teamID: number) {
        var apiReturn: SofaScoreEvent[] | undefined = undefined;
        var apiReturn1: SofaScoreEvent[] | undefined = undefined;
        var apiReturn2: SofaScoreEvent[] | undefined = undefined;
        try {
            var eventsFromPrimeiraLiga: SofaScoreEvent[] = [];
            if (teamData) {
                //TODO change api calls to dyanamic
                const axiosURL = "https://api.sofascore.com/api/v1/team/" + _teamID + "/events/last/0";
                const axiosURL1 = "https://api.sofascore.com/api/v1/team/" + _teamID + "/events/last/1";
                const axiosURL2 = "https://api.sofascore.com/api/v1/team/" + _teamID + "/events/last/2";
                const res = await axios.get(axiosURL);
                const res1 = await axios.get(axiosURL1);
                const res2 = await axios.get(axiosURL2);
                apiReturn = res.data["events"];
                apiReturn1 = res1.data["events"];
                apiReturn2 = res2.data["events"];

                var newArray = [...apiReturn!, ...apiReturn1!, ...apiReturn2!];
                eventsFromPrimeiraLiga = newArray!.filter((obj) => (obj.tournament.id === 52) && (obj.season.id === 52769));
            }
        } catch (err) {
            console.log(err);
        }
        return eventsFromPrimeiraLiga!.sort((a, b) => b.roundInfo.round - a.roundInfo.round);
    }

    async function GetTeamEvents(_id: number) {
        setTeamEvents(await getSofaScoreResults(_id));
    }

    async function GetTeamData(_id: number) {
        try {
            const axiosURL = "https://api.sofascore.com/api/v1/team/" + _id;
            const res = await axios.get(axiosURL);
            return res.data["team"];
        } catch (error) {
            console.log(error);
        }
        return null;
    }



    useEffect(() => {
        const getStats = async () => {
            const teamIDfromStorage = localStorage.getItem("teamID");
            if (teamIDfromStorage != null) {
                var teamID = parseInt(teamIDfromStorage, 10) || 0;
                if (!isNaN(teamID)) {
                    setTeamData(await GetTeamData(teamID));
                }
            }
            else {
                window.open("about:blank", "_self");
                window.close();
            }
        };
        getStats();
    }, []);

    useEffect(() => {
        const getStats = async () => {
            const teamIDfromStorage = localStorage.getItem("teamID");
            if (teamIDfromStorage != null) {
                var teamID = parseInt(teamIDfromStorage, 10) || 0;
                if (!isNaN(teamID)) {
                    await GetTeamEvents(teamID);
                }
            }
            else {
                window.open("about:blank", "_self");
                window.close();
            }
        };
        getStats();
    }, [teamData])



    return (
        <>
            <div>
                {teamData ?
                    <div className='teamGameStats'>
                        <ul>
                            <div className='teamGameStats__content'> {teamEvents?.map((eventObj, indexEvent) => {
                                return (
                                    <li key={eventObj.id}>
                                        <div className='event'>
                                            <div className='event__content'>
                                                <div className='event__content--component'>
                                                    <ContainerMatchResult
                                                        hometeamId={eventObj.homeTeam.id}
                                                        hometeamName={eventObj.homeTeam.name}
                                                        hometeamResult={eventObj.homeScore.display}
                                                        awayteamId={eventObj.awayTeam.id}
                                                        awayteamName={eventObj.awayTeam.name}
                                                        awayteamResult={eventObj.awayScore.display}
                                                        teamcolor={teamData?.teamColors?.primary}
                                                        teamcolor2={teamData?.teamColors?.secondary}
                                                    ></ContainerMatchResult>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                            </div>
                        </ul>
                    </div>
                    : <p>Loading...</p>}
            </div>
        </>
    );
};

export default TeamGameStats