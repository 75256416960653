import React from "react";
import "../styled/app.scss";
import "../styled/home.scss";
import * as styled from "../components/HomeButton";

const Home = () => {
  return (
    <div className="homePage">
      <div className="homePage__content">
        {/* <styled.MyButtons>Log In</styled.MyButtons>
                <styled.MyButtons>Register</styled.MyButtons>
                <styled.MyButtons>Sign Up</styled.MyButtons> */}

        <div className="homePage__content--button">
          <styled.MyButtons onClick={(event) => (window.location.href = "/PrimeiraLiga")}>Primeira Liga</styled.MyButtons>
        </div>
        <div className="homePage__content--button">
          <styled.MyButtons onClick={(event) => (window.location.href = "/TryFantasyLiveMarket")}>Try Fantasy</styled.MyButtons>
        </div>
        <div className="homePage__content--button">
          <styled.MyButtons onClick={(event) => (window.location.href = "/ManageTeam")}>Manage One Team</styled.MyButtons>
        </div>
      </div>
    </div>
  );
};

export default Home;
