import React from 'react';
import styled from 'styled-components'

interface Props {
    hometeamId: number;
    hometeamName: string;
    hometeamResult: number;
    awayteamId: number;
    awayteamName: string;
    awayteamResult: number;
    teamcolor: string;
    teamcolor2: string;
}

const CustomColorDiv = styled.div<{ teamcolor: string, teamcolor2: string }>`
border: ${props => (props.teamcolor)};
border-width: 5px;
border-style: solid;
outline: ${props => (props.teamcolor2)};
outline-width: 2px;
outline-style: auto;
padding: 5px 10px; 
width: fit-content;
`;

//TODO: create boxes for each club + score to center the results

export const ContainerMatchResult: React.FC<Props> = ({ hometeamId, hometeamName, hometeamResult, awayteamId,
    awayteamName, awayteamResult, teamcolor, teamcolor2 }) => {
    return (
        <CustomColorDiv teamcolor={teamcolor} teamcolor2={teamcolor2} style={{ width: "100%" }} className='ContainerMatchResult'>
            <div style={{ display: "flex", width: "350px", margin: "auto" }} className='ContainerMatchResult__content'>
                <div style={{ display: "flex", margin: "auto" }} className='ContainerMatchResult__content--homeTeam'>
                    <div style={{
                        backgroundImage: `url('https://api.sofascore.app/api/v1/team/${hometeamId}/image')`,
                        width: "30px",
                        height: "30px",
                        backgroundSize: "cover",
                        marginRight: "10px"
                    }} ></div>
                    <span style={{ lineHeight: "30px" }}>{hometeamName}</span>
                </div>

                <div style={{ margin: "auto", lineHeight: "30px" }}  className='ContainerMatchResult__content--result'><span>{hometeamResult}-{awayteamResult}</span></div>

                <div style={{ display: "flex", margin: "auto" }}  className='ContainerMatchResult__content--awayTeam'>
                    <span style={{ lineHeight: "30px" }}>{awayteamName}</span>
                    <div style={{
                        backgroundImage: `url('https://api.sofascore.app/api/v1/team/${awayteamId}/image')`,
                        width: "30px",
                        height: "30px",
                        backgroundSize: "cover",
                        marginLeft: "10px"
                    }} ></div>
                </div>
            </div>
        </CustomColorDiv>
    )
}