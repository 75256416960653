import styled from "styled-components";

interface StyleProps {
}


export const MyButtons = styled.button<StyleProps>`
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background-image: linear-gradient(to bottom right, #e05a4b, #5a3d3a);
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-image: linear-gradient(to bottom right, #5a3d3a, #e05a4b);
  }
  
  &:active {
    transform: translateY(2px);
    box-shadow: none;
    background-image: linear-gradient(to bottom right, #5a3d3a, #5a3d3a);
  }
  
`;