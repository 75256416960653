import axios from "axios";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { DropdownItems } from "../Types/CustomTypes";
import {
  TransfermarktClub,
  TransfermarktClubPlayerProfile,
  TransfermarktClubWithPlayers,
  TransfermarktCompetition,
  TransfermarktPlayerCustom,
  TransfermarktPlayerProfile,
  TransfermarktPlayerTransfers,
} from "../Types/TransfermarktTypes";
import { CustomDropdown } from "../components/CustomDropdown";
import { PlayerProfileComponent } from "../components/PlayerProfile";
import "../styled/tryfantasylivemarket.scss";

const TryFantasyLiveMarket = () => {
  //#region constants
  const [awaitingSelection, setAwaitingSelection] = useState(false);
  const [inputBudget, setInputBudget] = useState<number>();
  const [budgetLeft, setBudgetLeft] = useState<number>();
  const [budgetUsed, setBudgetUsed] = useState<number>(0);
  const [noClubSelected, setNoClubSelected] = useState(false);
  const [teamSelected, setTeamSelected] = useState<number>(0);
  const [playerModal, setplayerModal] = useState<TransfermarktPlayerCustom>();
  const [clubListDDL, setclubListDDL] = useState<DropdownItems[]>();
  const [clubListWithPlayers, setClubListWithPlayers] = useState<TransfermarktClubWithPlayers[]>();
  //const [listOfAllPlayers, setListOfAllPlayers] = useState<TransfermarktClubPlayerProfile[]>();
  const [selectedPlayersList, setSelectedPlayersList] = useState<TransfermarktClubPlayerProfile[]>([]);

  const changeNaDrop = (_teamID: number) => {
    setClubListWithPlayers(undefined);
    setTeamSelected(_teamID);
  };
  //#endregion

  useEffect(() => {
    const getData = async () => {
      var competition: TransfermarktCompetition = await ManageClubListSessionData();

      setclubListDDL(GetClubList(competition.clubs.sort((a, b) => a.name.localeCompare(b.name))));

      setNoClubSelected(teamSelected === -1);
      var showAllClubs = !teamSelected || teamSelected === 0;

      
      var _ALLclubListWithPlayers = await ManageClubListWithPlayersSessionData(competition.clubs);
      var _clubListWithPlayers = showAllClubs ? _ALLclubListWithPlayers : _ALLclubListWithPlayers.filter((c) => c.id == teamSelected);
      
      setClubListWithPlayers(_clubListWithPlayers);
    };
    getData();
  }, [teamSelected]);

  async function ManageClubListSessionData(): Promise<TransfermarktCompetition> {
    var _competition: TransfermarktCompetition;
    const storedSession = sessionStorage.getItem("Competition");

    if (storedSession) {
      _competition = JSON.parse(storedSession) as TransfermarktCompetition;
    } else {
      _competition = await GetCompetition();
      sessionStorage.setItem("Competition", JSON.stringify(_competition));
    }
    return _competition;
  }

  async function ManageClubListWithPlayersSessionData(_clubs: TransfermarktClub[]): Promise<TransfermarktClubWithPlayers[]> {
    var _ALLclubListWithPlayers: TransfermarktClubWithPlayers[] = [];
    const storedSession = sessionStorage.getItem("ClubListWithPlayers");

    if (storedSession) {
      _ALLclubListWithPlayers = JSON.parse(storedSession) as TransfermarktClubWithPlayers[];
    } else {
      _ALLclubListWithPlayers = await GetClubListWithPlayers(_clubs);
      sessionStorage.setItem("ClubListWithPlayers", JSON.stringify(_ALLclubListWithPlayers));
    }
    return _ALLclubListWithPlayers;
  }

  function GetClubList(clubs: TransfermarktClub[]) {
    var _clubListDDL: DropdownItems[] = [];
    clubs.forEach((club) => {
      var _clubDDL: DropdownItems = {
        label: club.name,
        value: parseInt(club.id),
      };
      _clubListDDL.push(_clubDDL);
    });
    return _clubListDDL;
  }

  async function GetClubListWithPlayers(_clubs: TransfermarktClub[]) {
    var clubListWithPlayers: TransfermarktClubWithPlayers[] = [];
    try {
      await Promise.all(
        _clubs.map(async (club) => {
          var listOfPlayers = await GetPlayers(club);
          var tempClubWithPlayers: TransfermarktClubWithPlayers = {
            id: parseInt(club.id),
            url: club.url,
            name: club.name,
            players: listOfPlayers,
          };
          clubListWithPlayers.push(tempClubWithPlayers);
        })
      );
    } catch (err) {
      console.log(err);
    }
    return clubListWithPlayers!;
  }

  async function GetCompetition() {
    var competition: TransfermarktCompetition;
    try {
      const res = await axios.get("https://transfermarkt-coutinho.fly.dev/competitions/PO1/clubs");
      competition = res.data;
    } catch (err) {
      console.log(err);
    }
    return competition!;
  }

  async function GetPlayers(_club: TransfermarktClub) {
    var players: TransfermarktClubPlayerProfile[];
    try {
      const res = await axios.get("https://transfermarkt-coutinho.fly.dev/clubs/" + _club.id + "/players");
      players = res.data["players"];
    } catch (err) {
      console.log(err);
    }
    return players!;
  }

  async function GetPlayerProfile(_player: TransfermarktClubPlayerProfile) {
    var player: TransfermarktPlayerProfile;
    try {
      const res = await axios.get("https://transfermarkt-coutinho.fly.dev/players/" + _player.id + "/profile");
      player = res.data;
    } catch (err) {
      console.log(err);
    }
    return player!;
  }

  async function GetPlayerTransfers(_player: TransfermarktClubPlayerProfile) {
    var transfer: TransfermarktPlayerTransfers;
    try {
      const res = await axios.get("https://transfermarkt-coutinho.fly.dev/players/" + _player.id + "/transfers");
      transfer = res.data;
    } catch (err) {
      console.log(err);
    }
    return transfer!;
  }

  //#region actions
  async function clickPlayerInfo(event: SyntheticEvent<Element, Event> | undefined, _player: TransfermarktClubPlayerProfile): Promise<void> {
    var playerWithProfile = await GetPlayerProfile(_player);
    var playerWithTransfers = await GetPlayerTransfers(_player);
    var playerCustom: TransfermarktPlayerCustom = {
      profile: playerWithProfile,
      transfer: playerWithTransfers,
    };
    setplayerModal(playerCustom);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    var inputNumber = parseFloat(value);
    setInputBudget(inputNumber);

    var _budgetUsed = budgetUsed === undefined ? 0 : budgetUsed;
    setBudgetLeft(inputNumber - _budgetUsed);
  }

  async function selectPlayer(event: ChangeEvent<HTMLInputElement>, _player: TransfermarktClubPlayerProfile): Promise<void> {
    if (event.target.checked) {
      setAwaitingSelection(true);

      setSelectedPlayersList((selectedPlayersList) => SortPlayerArray([...selectedPlayersList, _player]));

      setBudgetUsed(budgetUsed + getMarketValueNumber(_player));
      setBudgetLeft(inputBudget! - (budgetUsed + getMarketValueNumber(_player)));
      setAwaitingSelection(false);
    } else {
      var _newselectedPlayersList: TransfermarktClubPlayerProfile[] = selectedPlayersList.filter(function (obj) {
        return obj.id !== _player.id;
      });
      setSelectedPlayersList(_newselectedPlayersList);
      setBudgetUsed(budgetUsed - getMarketValueNumber(_player));
      setBudgetLeft(inputBudget! - (budgetUsed - getMarketValueNumber(_player)));
    }
  }

  function SortPlayerArray(_playerList: TransfermarktClubPlayerProfile[]): TransfermarktClubPlayerProfile[] {
    var sortOrder = [
      "Goalkeeper",
      "Sweeper",
      "Centre-Back",
      "Left-Back",
      "Right-Back",
      "Defensive Midfield",
      "Central Midfield",
      "Right Midfield",
      "Left Midfield",
      "Attacking Midfield",
      "Left Winger",
      "Right Winger",
      "Second Striker",
      "Centre-Forward",
    ];

    return _playerList.sort((a, b) => sortOrder.indexOf(a.position) - sortOrder.indexOf(b.position));
  }

  function getMarketValueNumber(_player: TransfermarktClubPlayerProfile): number {
    var playerfeeN = 0;
    var playerfeeInt = parseFloat(_player.marketValue.substring(1, _player.marketValue.length - 1));
    switch (_player.marketValue.slice(_player.marketValue.length - 1)) {
      case "k":
        playerfeeN = playerfeeInt / 1000;
        break;

      case "M":
        playerfeeN = playerfeeInt;
        break;

      default:
        break;
    }
    return playerfeeN;
  }

  //#endregion actions

  return (
    <div className="TryFantasyLiveMarket">
      <div className="TryFantasyLiveMarket__content">
        <div className="TryFantasyLiveMarket__content--pageData">
          <div className="pageData__customDropdown">
            <CustomDropdown ddItems={clubListDDL!} onChangeDrop={changeNaDrop} selectAll="ALL"></CustomDropdown>
          </div>
          {noClubSelected ? (
            <p>No Club Selected</p>
          ) : (
            <div className="pageData__playerList">
              <ul>
                {clubListWithPlayers ? (
                  clubListWithPlayers.map((clubList, index2) => {
                    return (
                      <li
                        key={clubList!.id}
                        style={{
                          width: "fit-content",
                          display: "inline-block",
                        }}>
                        <p className="playerList__clubName">{clubList!.name}</p>

                        <table>
                          <thead>
                            <tr>
                              <th className="name">name</th>
                              <th className="position">position</th>
                              <th className="value">value</th>
                              <th className="age">age</th>
                              <th className="btn"></th>
                            </tr>
                          </thead>

                          <tbody>
                            {clubList.players?.map((player, index2) => {
                              return (
                                <tr key={player!.id} id={player!.id.toString()}>
                                  <td className="name">
                                    {" "}
                                    <input type="checkbox" onChange={(event) => selectPlayer(event, player!)} disabled={awaitingSelection}></input> {player!.name}
                                  </td>
                                  <td className="position">{player!.position}</td>
                                  <td className="value">{player!.marketValue}</td>
                                  <td className="age">{player!.age}</td>
                                  <td className="btn">
                                    <Popup trigger={<button id="info"> +INFO </button>} modal onOpen={(event) => clickPlayerInfo(event, player!)}>
                                      {playerModal ? <PlayerProfileComponent playerInfo={playerModal}></PlayerProfileComponent> : <p>LOADING. . . </p>}
                                    </Popup>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </li>
                    );
                  })
                ) : (
                  <p>LOADING . . . </p>
                )}
              </ul>
            </div>
          )}
        </div>
        <div className="TryFantasyLiveMarket__content--pageSelection">
          <div className="pageSelection">
            <div className="pageSelection__input">
              <p>Select your budget:</p>
              <input type="number" onChange={handleChange}></input> Millions
            </div>
            {budgetLeft ? (
              <div className="pageSelection__selectionData">
                <div className="pageSelection__selectionData-budget">
                  <p className="pageSelection__selectionData--budgetLeft">budget left: {budgetLeft!.toFixed(3)}</p>
                  <p className="pageSelection__selectionData--budgetUsed">budget used: {budgetUsed!.toFixed(3)}</p>
                  <p className="pageSelection__selectionData--playerCount">player count: {selectedPlayersList!.length}</p>
                </div>
                <div className="pageSelection__selectionData--selectedPlayers">
                  <table>
                    <tbody>
                      {selectedPlayersList
                        ? selectedPlayersList.map((selectedPlayer, index) => {
                            return (
                              <tr className="selectedPlayers__player" key={selectedPlayer.id}>
                                <td className="selectedPlayers__name"> {selectedPlayer.name} </td>
                                <td className="selectedPlayers__age"> {selectedPlayer.age} </td>
                                <td className="selectedPlayers__position"> {selectedPlayer.position} </td>
                                <td className="selectedPlayers__marketValue">{selectedPlayer.marketValue} </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TryFantasyLiveMarket;
