import React from "react";
import styled from "styled-components";

interface Props {
  imgUrl: string;
  descText: string;
}

//linear-gradient(${(props) => props.teamcolor}, ${(props) => props.teamcolor2});
const Container = styled.div<{}>`
  background: #dbdada;
  width: 120px;
  height: 120px;
  padding: 5px;
  border: 1px solid black;
  display: inline-block;
`;

const Wrapper = styled.div<{}>`
  background-color: #fff;
  margin: auto;
`;

const Border = styled.div<{}>`
  background-color: #fff;
  border: 1px solid black;
  width: 120px;
  height: 120px;
  display: flex;
`;

const TeamLogo = styled.div<{}>``;

const TeamDesc = styled.div<{}>`
  text-align: center;
  font-size: 14px;
  height: 32px;
  display: flex;
`;

const TeamDescText = styled.span<{}>`
  margin: auto;
`;

const TeamImg = styled.div<{ url: string }>`
  background-image: url(${(props) => props.url});
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
  width: 80px;
  margin: auto;
`;

export const TeamWithLogo: React.FC<Props> = ({ descText, imgUrl }) => {
  return (
    <>
      <Container >
        <Border>
          <Wrapper>
            <TeamLogo>
              <TeamImg url={imgUrl}></TeamImg>
            </TeamLogo>
            <TeamDesc>
              <TeamDescText>{descText}</TeamDescText>
            </TeamDesc>
          </Wrapper>
        </Border>
      </Container>
    </>
  );
};
