import React from 'react';
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PrimeiraLiga from "./pages/PrimeiraLiga";
import TeamGameStats from "./pages/TeamGameStats";
import TeamH2H from "./pages/TeamH2H";
import TryFantasyLiveMarket from "./pages/TryFantasyLiveMarket";
import ManageTeam from "./pages/ManageTeam";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/primeiraliga" element={<PrimeiraLiga />} />
        <Route path="/teamgamestats" element={<TeamGameStats />} />
        <Route path="/teamh2h" element={<TeamH2H />} />
        <Route path="/tryfantasylivemarket" element={<TryFantasyLiveMarket />} />
        <Route path="/manageteam" element={<ManageTeam />} />
      </Routes>
    </>
  );
}

export default App;
