import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TeamH2H } from '../Types/CustomTypes';
import {
    SofaScoreEvent,
    SofaScoreTeam
} from "../Types/SofaScoreTypes";
import { CustomButton } from '../components/CustomButton';
import "../styled/styles.css";
import "../styled/teamh2h.scss";

const TeamH2HStats = () => {
    const [teamData, setTeamData] = useState<SofaScoreTeam>();
    const [teamEvents, setTeamEvents] = useState<SofaScoreEvent[]>();
    const [teamsList, setTeamsList] = useState<SofaScoreTeam[]>();
    const [teamH2h, setTeamH2h] = useState<TeamH2H>();
    const [showAllH2h, setShowAllH2h] = useState(false);
    const [allH2h, setAllH2h] = useState<TeamH2H[]>();

    async function getSofaScoreResults(_teamID: number) {
        var apiReturn: SofaScoreEvent[] | undefined = undefined;
        var apiReturn1: SofaScoreEvent[] | undefined = undefined;
        var apiReturn2: SofaScoreEvent[] | undefined = undefined;
        try {
            var eventsFromPrimeiraLiga: SofaScoreEvent[] = [];
            if (teamData) {

                var listOfEventsTotal: SofaScoreEvent[] = [];
                for (let index = 0; index < 10; index++) {

                    var listOfEvents: SofaScoreEvent[] = [];
                    const axiosURLEvents = "https://api.sofascore.com/api/v1/team/" + _teamID + "/events/last/" + index;
                    const resEvents = await axios.get(axiosURLEvents);
                    listOfEvents = resEvents.data["events"];
                    listOfEventsTotal = [...listOfEventsTotal, ...listOfEvents];

                    var currentSeasonEnded = listOfEvents.find((obj) => (obj.tournament.id === 52) && (obj.season.id !== 52769));
                    if (currentSeasonEnded) index = 10;
                }
                eventsFromPrimeiraLiga = listOfEventsTotal!.filter((obj) => (obj.tournament.id === 52) && (obj.season.id === 52769));

                var listOfHomeTeams = eventsFromPrimeiraLiga.map(a => a.homeTeam);
                var listOfAwayTeams = eventsFromPrimeiraLiga.map(a => a.awayTeam);

                var listOfAllTeams = [...listOfHomeTeams, ...listOfAwayTeams].filter((team) => (team.id !== teamData?.id)).sort((a, b) => a.name.localeCompare(b.name));

                var listOfUniqueTeams: SofaScoreTeam[] = [];
                listOfAllTeams.forEach(element => {
                    if (!listOfUniqueTeams.some(({ id }) => id === element.id)) listOfUniqueTeams.push(element);
                });
                setTeamsList(listOfUniqueTeams);
                setAllH2h(getObjectAllH2h(_teamID, eventsFromPrimeiraLiga)!);
            }
        } catch (err) {
            console.log(err);
        }
        return eventsFromPrimeiraLiga!.sort((a, b) => b.roundInfo.round - a.roundInfo.round);
    }

    function getObjectAllH2h(_teamID: number, eventsFromPrimeiraLiga: SofaScoreEvent[]) {
        var temp: TeamH2H[] = [];
        var sortedEvents = eventsFromPrimeiraLiga.sort((a, b) => (a.roundInfo.round > b.roundInfo.round) ? 1 : -1);
        sortedEvents.forEach(event => {
            var isHomeTeam = event.homeTeam.id === _teamID;
            var tempTh2h: TeamH2H = {};
            var teamAgainst = isHomeTeam ? event.awayTeam : event.homeTeam;

            if (temp.some(t => t.teamAgainst?.id === teamAgainst.id)) {
                var index = temp.findIndex(t => t.teamAgainst?.id === teamAgainst.id);
                temp[index].pointsWon! += GetPointsWonLost(true, isHomeTeam, event.winnerCode);
                temp[index].pointsLost! += GetPointsWonLost(false, isHomeTeam, event.winnerCode);
                temp[index].goalsScored! += isHomeTeam ? event.homeScore.display : event.awayScore.display;
                temp[index].goalsConceded! += isHomeTeam ? event.awayScore.display : event.homeScore.display;
            }
            else {
                tempTh2h.teamSelected = isHomeTeam ? event.homeTeam : event.awayTeam;
                tempTh2h.teamAgainst = teamAgainst;
                tempTh2h.pointsWon = GetPointsWonLost(true, isHomeTeam, event.winnerCode);
                tempTh2h.pointsLost = GetPointsWonLost(false, isHomeTeam, event.winnerCode);
                tempTh2h.goalsScored = isHomeTeam ? event.homeScore.display : event.awayScore.display;
                tempTh2h.goalsConceded = isHomeTeam ? event.awayScore.display : event.homeScore.display;
                temp.push(tempTh2h);
            }
        });

        return temp;
    }

    function GetPointsWonLost(showWon: boolean, isHome: boolean, winnerCode: number): number {
        var pointsWon = 0;
        var pointsLost = 0;

        if (isHome)
            switch (winnerCode) {
                case 1: pointsWon = 3; break;
                case 3: pointsWon = 1; pointsLost = 2; break;
                default: pointsLost = 3; break;
            }
        else
            switch (winnerCode) {
                case 1: pointsLost = 3; break;
                case 3: pointsWon = 1; pointsLost = 2; break;
                default: pointsWon = 3; break;
            }

        return showWon ? pointsWon : pointsLost;
    }

    function Statsh2h(event: React.MouseEvent<HTMLDivElement, MouseEvent>, ssEvent?: SofaScoreTeam | undefined): void {
        if (ssEvent) {
            var teamAgainst: SofaScoreTeam = ssEvent!;

            var eventList: SofaScoreEvent[] = [];
            teamEvents!.forEach(event => {
                if (event.awayTeam.id === teamAgainst.id || event.homeTeam.id === teamAgainst.id) eventList!.push(event);
            });

            var match1 = eventList[0];
            var match2 = eventList.length > 1 ? eventList[1] : null;
            var goalsScored = 0;
            var goalsConceded = 0;
            var pointsWon = 0;
            var pointsLost = 0;

            var isMatch1Home = (match1.homeTeam.id === teamData!.id);
            goalsScored = isMatch1Home ? match1.homeScore.display : match1.awayScore.display;
            goalsConceded = isMatch1Home ? match1.awayScore.display : match1.homeScore.display;
            if (isMatch1Home) {
                switch (match1.winnerCode) {
                    case 1: pointsWon = 3; break;
                    case 3: pointsWon = 1; pointsLost = 2; break;
                    default: pointsLost = 3; break;
                }
            }
            else {
                switch (match1.winnerCode) {
                    case 1: pointsLost = 3; break;
                    case 3: pointsWon = 1; pointsLost = 2; break;
                    default: pointsWon = 3; break;
                }
            }

            if (match2 !== null) {
                var isMatch2Home = (match2.homeTeam.id === teamData!.id);
                goalsScored += isMatch2Home ? match2.homeScore.display : match2.awayScore.display;
                goalsConceded += isMatch2Home ? match2.awayScore.display : match2.homeScore.display;

                if (isMatch2Home) {
                    switch (match2.winnerCode) {
                        case 1: pointsWon += 3; break;
                        case 3: pointsWon += 1; pointsLost += 2; break;
                        default: pointsLost += 3; break;
                    }
                }
                else {
                    switch (match2.winnerCode) {
                        case 1: pointsLost += 3; break;
                        case 3: pointsWon += 1; pointsLost += 2; break;
                        default: pointsWon += 3; break;
                    }
                }
            }

            var temp2h2: TeamH2H = {
                teamAgainst: teamAgainst,
                teamSelected: teamData!,
                match1: match1,
                match2: match2 !== null ? match2 : undefined,
                goalsConceded: goalsConceded,
                goalsScored: goalsScored,
                pointsLost: pointsLost,
                pointsWon: pointsWon
            };
            setTeamH2h(temp2h2);
        }
    }

    async function GetTeamEvents(_id: number) {
        setTeamEvents(await getSofaScoreResults(_id));
    }

    async function GetTeamData(_id: number) {
        try {
            const axiosURL = "https://api.sofascore.com/api/v1/team/" + _id;
            const res = await axios.get(axiosURL);
            return res.data["team"];
        } catch (error) {
            console.log(error);
        }
        return null;
    }

    useEffect(() => {
        const getStats = async () => {
            const teamIDfromStorage = localStorage.getItem("teamID");
            if (teamIDfromStorage != null) {
                var teamID = parseInt(teamIDfromStorage, 10) || 0;
                if (!isNaN(teamID)) {
                    setTeamData(await GetTeamData(teamID));
                }
            }
            else {
                window.open("about:blank", "_self");
                window.close();
            }
        };
        getStats();
    }, []);

    useEffect(() => {
        const getStats = async () => {
            const teamIDfromStorage = localStorage.getItem("teamID");
            if (teamIDfromStorage != null) {
                var teamID = parseInt(teamIDfromStorage, 10) || 0;
                if (!isNaN(teamID)) {
                    await GetTeamEvents(teamID);
                }
            }
            else {
                window.open("about:blank", "_self");
                window.close();
            }
        };
        getStats();
    }, [teamData])

    function ShowAllPoints(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        setShowAllH2h(!showAllH2h);
    }

    return (
        <div className='TeamH2h'>
            <div className='TeamH2h__topInfo'>
                <p>LOST: {teamH2h?.pointsLost}</p>
                <p>WON:  {teamH2h?.pointsWon}</p>
                <div className='TeamH2h__topInfo--btnShowAll'>
                    <CustomButton btnText="SHOW ALL" onClick={(event) => ShowAllPoints(event)}></CustomButton>
                </div>
            </div>

            {teamData ? <div className='TeamH2h__clubList' >
                <div className='TeamH2h__clubList--list' >
                    <ul >
                        <li key={teamData!.id} >
                            <div className='clubList'>
                                <div className='clubList__team'>
                                    <div className='clubList__team--img' style={{
                                        backgroundImage: `url('https://api.sofascore.app/api/v1/team/${teamData!.id}/image')`,
                                    }} ></div>
                                    <div className='clubList__team--txt'>{teamData!.name}</div>
                                </div>
                            </div>
                        </li>
                        {teamsList?.map((eventObj, indexEvent) => {
                            return (
                                <li key={eventObj.id}>
                                    <div className='clubList'>
                                        <div className='clubList__team' onClick={(event) => Statsh2h(event, eventObj)}  >
                                            <div className='clubList__team--img' style={{
                                                backgroundImage: `url('https://api.sofascore.app/api/v1/team/${eventObj.id}/image')`,
                                            }} ></div>
                                            <div className='clubList__team--txt'>{eventObj.name}
                                            </div>
                                        </div>
                                        {showAllH2h ?
                                            <div className='h2hInfo'>
                                                <p>WON: {JSON.stringify(allH2h?.find(h2h => h2h.teamAgainst?.id === eventObj.id)?.pointsWon, null, 2)}</p>
                                                <p>LOST: {JSON.stringify(allH2h?.find(h2h => h2h.teamAgainst?.id === eventObj.id)?.pointsLost, null, 2)}</p>

                                            </div>
                                            : null}</div>


                                </li>
                            );
                        })}
                    </ul>
                </div>

            </div> : <p>Loading...</p>}
        </div>
    );
};

export default TeamH2HStats

