import axios from "axios";
import { MouseEvent, MouseEventHandler, useEffect, useState } from "react";
import "reactjs-popup/dist/index.css";
import {
    TransfermarktClub,
    TransfermarktClubPlayerProfile,
    TransfermarktClubWithPlayers,
    TransfermarktCompetition,
    TransfermarktPlayerCustom,
    TransfermarktPlayerProfile,
} from "../Types/TransfermarktTypes";
import { TeamWithLogo } from "../components/TeamWithLogo";
import "../styled/manageteam.scss";
import "../styled/styles.css";
import { DropdownItems } from "../Types/CustomTypes";
import { BudgetContainer } from "../components/BudgetContainer";
import { CustomDropdown } from "../components/CustomDropdown";

const ManageTeam = () => {
    //#region constants
    const [clubListDDL, setclubListDDL] = useState<DropdownItems[]>();
    const [FullClubList, setFullClubList] = useState<TransfermarktClub[] | undefined>(undefined);
    const [ClubPlayerList, setClubPlayerList] = useState<TransfermarktClubPlayerProfile[]>([]);
    const [SoldPlayerList, setSoldPlayerList] = useState<TransfermarktClubPlayerProfile[]>([]);
    const [OtherPlayerList, setOtherPlayerList] = useState<TransfermarktClubPlayerProfile[]>([]);
    const [PlayerDetailList, setPlayerDetailList] = useState<TransfermarktPlayerProfile[]>([]);
    const [InputBudget, setInputBudget] = useState<number>(0);
    const [BudgetLeft, setBudgetLeft] = useState<number>(0);
    const [BudgetUsed, setBudgetUsed] = useState<number>(0);
    const [teamSelected, setTeamSelected] = useState<number>(0);
    //#endregion constants

    useEffect(() => {
        const getData = async () => {
            var _competition: TransfermarktCompetition = await ManageCompetitionSessionData();
            var _clubList: TransfermarktClub[] = await ManageClubListSessionData(_competition);

            setclubListDDL(GetClubList(_clubList.sort((a, b) => a.name.localeCompare(b.name))));
            setFullClubList(_clubList);
        };

        getData();
    }, [ClubPlayerList]);

    useEffect(() => {
        setBudgetLeft(InputBudget - BudgetUsed);
        return () => {};
    }, [InputBudget]);

    useEffect(() => {
        if (teamSelected > 0) {
            const getData = async () => {
                const _teamData = await GetTeamData(teamSelected);
                const _playerList = await GetPlayerListOrderedContractFromClub(_teamData);

                setOtherPlayerList(_playerList);
            };
            getData();
        }
        return () => {};
    }, [teamSelected]);

    //#region FUNCTIONS
    async function ManageCompetitionSessionData(): Promise<TransfermarktCompetition> {
        var _competition: TransfermarktCompetition;
        const storedSession = sessionStorage.getItem("ManageTeamCompetition");

        if (storedSession) {
            _competition = JSON.parse(storedSession) as TransfermarktCompetition;
        } else {
            _competition = await GetCompetition();
            sessionStorage.setItem("ManageTeamCompetition", JSON.stringify(_competition));
        }
        return _competition;
    }

    async function ManageClubListSessionData(_competition: TransfermarktCompetition): Promise<TransfermarktClub[]> {
        var _teamList: TransfermarktClub[] = [];
        const storedSession = sessionStorage.getItem("TransfermarktClubList");

        if (storedSession) {
            _teamList = JSON.parse(storedSession) as TransfermarktClub[];
        } else {
            await Promise.all(
                _competition.clubs.map(async (club) => {
                    var _teamData: TransfermarktClub = await GetTeamData(parseInt(club.id));
                    _teamList.push(_teamData);
                })
            );
            sessionStorage.setItem("TransfermarktClubList", JSON.stringify(_teamList.sort((a, b) => a.name.localeCompare(b.name))));
        }
        return _teamList;
    }

    async function GetCompetition() {
        var competition: TransfermarktCompetition;
        try {
            const res = await axios.get("https://transfermarkt-coutinho.fly.dev/competitions/PO1/clubs");
            competition = res.data;
        } catch (err) {
            console.log(err);
        }
        return competition!;
    }

    async function GetTeamData(_id: number) {
        var _teamData: TransfermarktClub;
        try {
            const res = await axios.get("https://transfermarkt-coutinho.fly.dev/clubs/" + _id + "/profile");
            _teamData = res.data;
        } catch (err) {
            console.log(err);
        }
        return _teamData!;
    }

    async function GetPlayers(_club: TransfermarktClub) {
        var playersApi: TransfermarktClubPlayerProfile[];
        var playersOut: TransfermarktClubPlayerProfile[] = [];
        try {
            const res = await axios.get("https://transfermarkt-coutinho.fly.dev/clubs/" + _club.id + "/players");
            playersApi = res.data["players"];

            var _playerProfileList: TransfermarktPlayerProfile[] = [];
            const _currentYear = new Date().getFullYear();
            const _seasonEnd = new Date("Aug 01, " + _currentYear);
            await Promise.all(
                playersApi.map(async (player) => {
                    var _playerProfile = await GetPlayerDetails(player.id);

                    var _contractDate = new Date(_playerProfile.club.contractExpires);
                    var _contractExpiresThisSeason = _contractDate < _seasonEnd;
                    _playerProfile._contractExpiresThisSeason = _contractExpiresThisSeason;

                    _playerProfileList.push(_playerProfile);
                    player._contractExpiresThisSeason = _contractExpiresThisSeason;
                    playersOut.push(player);
                })
            );
            setPlayerDetailList(_playerProfileList);
        } catch (err) {
            console.log(err);
        }
        return playersOut!;
    }

    async function GetPlayerDetails(_playerId: number) {
        var playerProfile: TransfermarktPlayerProfile;
        try {
            const res = await axios.get("https://transfermarkt-coutinho.fly.dev/players/" + _playerId + "/profile");
            playerProfile = res.data;
        } catch (err) {
            console.log(err);
        }
        return playerProfile!;
    }

    function SortPlayerArray(_playerList: TransfermarktClubPlayerProfile[]): TransfermarktClubPlayerProfile[] {
        var sortOrder = [
            "Goalkeeper",
            "Sweeper",
            "Centre-Back",
            "Left-Back",
            "Right-Back",
            "Defensive Midfield",
            "Central Midfield",
            "Right Midfield",
            "Left Midfield",
            "Attacking Midfield",
            "Left Winger",
            "Right Winger",
            "Second Striker",
            "Centre-Forward",
        ];

        return _playerList.sort((a, b) => sortOrder.indexOf(a.position) - sortOrder.indexOf(b.position));
    }

    function GetPlayersByContractExpiration(
        _playerList: TransfermarktClubPlayerProfile[],
        isExpired: boolean = false
    ): TransfermarktClubPlayerProfile[] {
        return isExpired ? _playerList.filter((a) => a._contractExpiresThisSeason) : _playerList.filter((a) => !a._contractExpiresThisSeason);
    }

    function GetClubList(clubs: TransfermarktClub[]) {
        var _clubListDDL: DropdownItems[] = [];
        clubs.forEach((club) => {
            var _clubDDL: DropdownItems = {
                label: club.name,
                value: parseInt(club.id),
            };
            _clubListDDL.push(_clubDDL);
        });
        return _clubListDDL;
    }

    async function GetPlayerListOrderedContractFromClub(_club: TransfermarktClub): Promise<TransfermarktClubPlayerProfile[]> {
        var _playerList = await GetPlayers(_club);
        var _playerListExpired = GetPlayersByContractExpiration(_playerList, true);

        await Promise.all(
            _playerListExpired.map(async (player, index) => {
                [..._playerListExpired][index]._signValue = 0;
            })
        );

        var _playerListWitContract = GetPlayersByContractExpiration(_playerList, false);

        await Promise.all(
            _playerListWitContract.map(async (player, index) => {
                [..._playerListWitContract][index]._signValue = GetMarketValueNumber(player);
            })
        );

        var _playerListExpiredSorted = SortPlayerArray(_playerListExpired);
        var _playerListWitContractSorted = SortPlayerArray(_playerListWitContract);

        return [..._playerListExpiredSorted, ..._playerListWitContractSorted];
    }

    function GetMarketValueNumber(_player: TransfermarktClubPlayerProfile): number {
        var playerfeeN = 0;
        if (_player.marketValue) {
            var playerfeeInt = parseFloat(_player.marketValue.substring(1, _player.marketValue.length - 1));
            switch (_player.marketValue.slice(_player.marketValue.length - 1)) {
                case "k":
                    playerfeeN = playerfeeInt / 1000;
                    break;

                case "m":
                    playerfeeN = playerfeeInt;
                    break;

                default:
                    break;
            }
        }
        return playerfeeN;
    }
    //#endregion FUNCTIONS

    //#region actions
    async function teamClick(event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, _club: TransfermarktClub): Promise<void> {
        setFullClubList(undefined);
        var _clubPlayerListOrderedContract = await GetPlayerListOrderedContractFromClub(_club);
        setClubPlayerList(_clubPlayerListOrderedContract.filter((a) => !a._contractExpiresThisSeason));
        setSoldPlayerList(_clubPlayerListOrderedContract.filter((a) => a._contractExpiresThisSeason));

        var _clubList: TransfermarktClub[] = [];
        _clubList.push(_club);
        setFullClubList(_clubList);
    }

    function sellPlayer(_player: TransfermarktClubPlayerProfile): void {
        var tempPlayerList = ClubPlayerList.filter((player) => player.id !== _player.id);
        setClubPlayerList(tempPlayerList);

        var tempSoldPlayerList = SoldPlayerList;
        tempSoldPlayerList.push(_player);
        setSoldPlayerList(tempSoldPlayerList);

        var tempBudgetLeft = BudgetLeft + GetMarketValueNumber(_player);
        setBudgetLeft(tempBudgetLeft);
    }

    function InputBudgetChanged(_target: string): void {
        setInputBudget(parseInt(_target));
    }

    const changeNaDrop = (_teamID: number) => {
        setOtherPlayerList([]);
        setTeamSelected(_teamID);
    };

    function buyPlayer(_player: TransfermarktClubPlayerProfile): void {
        var tempClubPlayerList = ClubPlayerList;
        tempClubPlayerList.push(_player);
        setClubPlayerList(tempClubPlayerList);

        var tempOtherPlayerList = OtherPlayerList.filter((player) => player.id !== _player.id);
        setOtherPlayerList(tempOtherPlayerList);

        setBudgetLeft(BudgetLeft - _player._signValue);
        setBudgetUsed(BudgetUsed + _player._signValue);
    }
    //#endregion actions

    return (
        <div className="manageTeam">
            <div className="manageTeam__listOfClubs">
                <div className="manageTeam__listOfClubs--title">
                    <span>Choose your team:</span>
                </div>
                <ul className="manageTeam__listOfClubs--club">
                    {FullClubList ? (
                        FullClubList.map((clubList, index) => {
                            return (
                                <li key={clubList.id} onClick={(event) => teamClick(event, clubList)}>
                                    <TeamWithLogo imgUrl={clubList.image} descText={clubList.name}></TeamWithLogo>
                                </li>
                            );
                        })
                    ) : (
                        <p>LOADING . . . </p>
                    )}

                    {}

                    <div>
                        <BudgetContainer
                            onChangeInput={InputBudgetChanged}
                            budgetUsed={BudgetUsed.toFixed(2)}
                            budgetLeft={BudgetLeft.toFixed(2)}></BudgetContainer>
                    </div>
                </ul>
                <div className="manageTeam__listOfClubs--customDropdown">
                    <CustomDropdown ddItems={clubListDDL!} onChangeDrop={changeNaDrop}></CustomDropdown>
                </div>
            </div>

            <div className="manageTeam__playersList">
                <div className="manageTeam__playersList--tableOriginalClub">
                    <table>
                        <tbody>
                            {ClubPlayerList
                                ? ClubPlayerList.map((player, index) => {
                                      return (
                                          <tr key={"tableOriginalClub" + player.id}>
                                              <td>{player.name}</td>
                                              <td>{player.position}</td>
                                              <td>{player.age}</td>
                                              <td>{player.marketValue}</td>
                                              <td>
                                                  <button onClick={() => sellPlayer(player)} disabled={player._contractExpiresThisSeason}>
                                                      SELL
                                                  </button>
                                              </td>
                                          </tr>
                                      );
                                  })
                                : null}
                        </tbody>
                    </table>
                </div>
                <div className="manageTeam__playersList--tablePlayersSold">
                    <table>
                        <tbody>
                            {SoldPlayerList
                                ? SoldPlayerList.map((player, index) => {
                                      return (
                                          <tr key={"tablePlayersSold-" + player.id}>
                                              <td>{player.name}</td>
                                              <td>{player.marketValue}</td>
                                          </tr>
                                      );
                                  })
                                : null}
                        </tbody>
                    </table>
                </div>
                <div className="manageTeam__playersList--tableOtherClubs">
                    {}
                    <table>
                        <tbody>
                            {OtherPlayerList.length > 0 ? (
                                OtherPlayerList.map((player, index) => {
                                    return (
                                        <tr key={"tableOtherClubs" + player.id}>
                                            <td>
                                                <button onClick={() => buyPlayer(player)}>BUY</button>
                                            </td>
                                            <td>{player._signValue}</td>
                                            <td>{player.name}</td>
                                            <td>{player.position}</td>
                                            <td>{player.age}</td>
                                            <td>{player.marketValue}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <p>NO TEAM SELECTED</p>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ManageTeam;
